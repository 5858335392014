import '../reports/react-leaflet.css';
import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MapContainer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import ParkIcon from '../../../asserts/tree.svg'
import IrrigationIcon from '../../../asserts/drop.svg'
import PaintIcon from '../../../asserts/paint.svg'
import CutIcon from '../../../asserts/cut.svg'
import RoadIcon from '../../../asserts/road.svg'
import LightIcon from '../../../asserts/light.svg'
import TrashIcon from '../../../asserts/trash.svg'
import SweepIcon from '../../../asserts/sweep.svg'
import Lotes from '../../../asserts/lotes.svg'
import AltoIcon from '../../../asserts/alto.svg'
import GobiernoIcon from '../../../asserts/gobierno.svg'
import UrbanoIcon from '../../../asserts/urbanito.svg'
import { DatePicker, Modal, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ReportDetails } from '../reports/ReportDetails'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../store';
import { prMain } from '../../../themeConfig';
import { apiGetReportsFilter } from '../../../utils/api';

const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY

const classes = {
  dialogTitle: {
    color: prMain,
    fontWeight: 'bold',
    textAlign: 'right',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '20px'
  },
}

const DraggableMarker = props => {
  const { position, setPosition, dataRow } = props;
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )
  var color;
  if (dataRow.expired && dataRow.status.id !== 4) {
    color = '#999'
  } else {

    switch (dataRow.status.id) {
      case 1:
        color = '#ff4949';
        break;
      case 2:
        color = '#ffc700';
        break;
      case 3:
        color = '#ff9800';
        break;
      case 4:
        color = '#71cf71';
        break;
      default:
        color = '#fff';
        break;
    }
  }
  var ico;
  switch (dataRow.servicesDto.id) {
    case 1:
      // ico = ParkIcon;
      ico = SweepIcon;
      break;
    case 2:
      ico = IrrigationIcon;
      break;
    case 3:
      ico = PaintIcon;
      break;
    case 4:
      // ico = CutIcon;
      ico = ParkIcon;
      break;
    case 5:
      ico = CutIcon;
      // ico = RoadIcon;
      break;
    case 6:
      ico = LightIcon;
      break;
    case 7:
      ico = TrashIcon;
      break;
    case 8:
      ico = RoadIcon;
      // ico = SweepIcon;
      break;
    case 9:
      ico = Lotes;
      // ico = SweepIcon;
      break;
    case 10:
      ico = UrbanoIcon;
      break;
    case 11:
      ico = AltoIcon;
      break;
    case 12:
      ico = GobiernoIcon;
      break;
    default:
      ico = SweepIcon;
      break;

  }

  var svgBck = `<svg height="70" width="70"  xmlns='http://www.w3.org/2000/svg'> <circle cx='35' cy='35' r='30' fill='${color}' > </circle></svg>`
  var urlBck = encodeURI("data:image/svg+xml," + svgBck).replace('#', '%23');

  var mark = L.icon({
    iconUrl: ico,
    iconSize: [22, 22],
    iconAnchor: [11, 11],
    shadowUrl: urlBck,
    shadowSize: [28, 28],
    shadowAnchor: [14, 14]

  });


  const [openDetails, setOpenDetails] = useState(false);

  const handleCloseDetails = (typeNotification) => {
    setOpenDetails(false);
    props.getDataReport()
  }

  return (

    <div>

      <Marker
        draggable={false}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={mark}
      >
        <Popup minWidth={90}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

            <p>Reporte de {dataRow.servicesDto.name} con folio {dataRow.id}</p>
            <span onClick={() => setOpenDetails(true)} style={{ cursor: 'pointer', color: prMain, marginTop: -15 }}>Ver detalles</span>

          </div>
        </Popup>
      </Marker>

      {
        openDetails &&
        <Modal
          visible={openDetails}
          onCancel={handleCloseDetails}
          footer={null}
          closable={true}
          closeIcon={<CloseCircleOutlined onClick={handleCloseDetails} style={{ color: 'red' }} />}
          centered
          title={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {dataRow.length !== 0 &&
                <Typography style={classes.dialogTitle} variant="h6">
                  {dataRow.status.id === 1 && 'Pendiente'}
                  {dataRow.status.id === 2 && 'En proceso'}
                  {dataRow.status.id === 3 && 'En revisión'}
                  {dataRow.status.id === 4 && 'Solucionado'}
                  {(dataRow.reasonForDelete === 'No reason found' && !dataRow.deletedAt) ? "" : " (Solicitado para completar)"}
                </Typography>}
            </div>
          }
        >
          <ReportDetails refreshReports={props.refreshReports} getDataReport={props.getDataReport} data={dataRow} onCloseDetails={handleCloseDetails} setChangeStatus={props.setChangeStatus} changeStatus={props.changeStatus} />

        </Modal>
      }

    </div>
  )
};

export const ReportWeekMap = () => {

  const [toDate, setToDate] = useState(moment().endOf('isoWeek').format('YYYY/M/D'));
  const [fromDate, setFromDate] = useState(moment().startOf('isoWeek').format('YYYY/M/D'));
  const [changeStatus, setChangeStatus] = useState(false);
  const [reports, setReports] = useState([]);
  const [loadingReports, setloadingReports] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const dispatch = useDispatch()

  const [position, setPosition] = useState({ lat: 28.1833, lng: -105.467 })
  const [zoom] = useState(14);

  const getDataReport = () => {
    dispatch(notificationsActions.update());
  }

  useEffect(() => {

    let from = new Date(fromDate)
    let to = new Date(toDate)
    setloadingReports(true)

    apiGetReportsFilter(from, to).then(res => {


      const filter = res.data.filter(item => {
        switch (item.status.id) {
          case 1:
            return moment(item.createdAt) >= moment(from) && moment(item.createdAt) <= moment(to)

          case 2:
            return item.assignedAt && (moment(item.assignedAt) >= moment(from) && moment(item.assignedAt) <= moment(to))

          case 3:
            return item.inReviewAt && (moment(item.inReviewAt) >= moment(from) && moment(item.inReviewAt) <= moment(to))

          case 4:
            return item.completedAt && (moment(item.completedAt) >= moment(from) && moment(item.completedAt) <= moment(to))


          default:
            return null
        }
      })
      setReports(filter)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setloadingReports(false)
    })

    setTimeout(() => {
      setRefresh(!refresh)

    }, [120000])

  }, [toDate, fromDate, refresh])

  const handleRange = (date, dateString) => {
    setFromDate(moment(date).startOf('isoWeek').format('YYYY/M/D'))
    setToDate(moment(date).endOf('isoWeek').format('YYYY/M/D'))
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: 5, padding: 10, height: 360 }}>
      <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography.Text style={{ color: '#6D6D6D', fontSize: 18 }}>Reportes totales de la semana</Typography.Text>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
          <DatePicker
            onChange={handleRange}
            defaultValue={moment()}
            picker='week'
            size='small'
            locale={locale}
            allowClear={false}
          />
          <Typography.Text style={{ color: '#6D6D6D', fontSize: 12, marginTop: 10 }}>Del {moment(fromDate).format('DD/MM/YYYY')} al {moment(toDate).format('DD/MM/YYYY')}</Typography.Text>
        </div>
      </section>
      <div style={{ width: '100%', height: 265, position: 'relative' }}>
        {
          loadingReports &&
          <div className="linear-activity">
            <div className="indeterminate"></div>
          </div>
        }
        <MapContainer tap={false} id="map" center={position} zoom={zoom} scrollWheelZoom={true}>
          <ReactLeafletGoogleLayer apiKey={googleKey} type={'roadmap'} />
          {reports.map((item, index) => (
            <DraggableMarker key={index} refreshReports={setRefresh} getDataReport={getDataReport} changeStatus={changeStatus} setChangeStatus={setChangeStatus} position={{ lat: item.latitude, lng: item.longitude }} setPosition={setPosition} dataRow={item} />

          ))}
        </MapContainer>
      </div>
    </div>
  );
}
